<template>
  <div class="homePage">
    <HeaderView></HeaderView>

    <!-- Banner Images Done -->
    <div class="image-container position-relative banner">
      <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(image, index) in images" :key="index">
          <img :src="image" alt="Image Slide" style="height: 600px" />
        </swiper-slide>
      </swiper>
      <div
        class="container position-absolute bottom-0 start-50 translate-middle-x"
        style="z-index: 1; font-family: 'Times New Roman', Times, serif"
      >
        <h3
          class="text-white animate__animated animate__bounceInLeft animate__duration-5s"
        >
          Hello!!!
        </h3>
        <h1
          class="text-info animate__animated animate__zoomIn animate__duration-5s"
          style="
            font-family: 'Times New Roman', Times, serif;
            font-size: 4rem;
            letter-spacing: 3px;
          "
        >
          Welcome to the 1st Waterboom of Mandalay!
        </h1>
        <h2
          class="text-white animate__animated animate__bounceInRight animate__duration-5s"
        >
          Experience The Best Way to FUN!
        </h2>
        <button
          class="btn btn-warning text-white fw-bold px-3 py-2 mt-3 mb-5"
          style="border-radius: 20px"
        >
          Read More <span> > </span>
        </button>
      </div>
    </div>

    <!-- Covid-19 Done -->
    <div class="card text-danger mx-auto">
      <div class="card-body">
        <h1
          class="text-center fw-bolder my-5 animate__animated animate__zoomIn animate__duration-3s"
        >
          Covid-19 protocol
        </h1>
        <p class="animate__animated animate__zoomIn animate__duration-5s">
          Let us keep your family safe while you enjoy our place. We will
          continue to follow all guidelines to ensure your safety. How We’re
          Ready for You
        </p>
        <ul
          class="list-unstyled animate__animated animate__zoomIn animate__duration-5s"
        >
          <li class="mb-2">– Employee Temperature Screenings</li>
          <li class="mb-2">– Social Distancing</li>
          <li class="mb-2">– Increased Cleaning & Sanitization</li>
          <li class="mb-2">– Contactless Payment</li>
        </ul>
      </div>
    </div>

    <!-- Water Park Done -->
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3"></div>
        <div class="col-lg-6 mb-3">
          <h2 class="text-info">Explore Our Water Park</h2>
        </div>
        <div class="col-lg-5 col-12 d-flex align-items-center">
          <img
            src="../../public/staticImages/home1.jpg"
            class="img-fluid"
            style="height: 350px"
            alt="Contact Image"
          />
        </div>
        <div
          class="col-md-7 col-12 shadow-lg p-5"
          style="border: 10px solid white; background-color: white"
        >
          <div class="card rounded-0" style="border: 1px solid orange">
            <div class="card-body p-5">
              <h5 class="text-secondary fw-bold">Explore our Water Park</h5>
              <h2 class="text-info">Water House</h2>
              <p class="text-secondary">
                Waterhouse Pool is one of the biggest water fun area in Mandalay
                Water Boom. Standing by the Avengers hero statues and kids can
                stand by those statues when water comes down from those huge
                buckets. A lot of fun & everyone likes it.
              </p>
              <h2 class="text-info">Wave Pool</h2>
              <p class="text-secondary">
                Tsunami Wave Pool is the essential attraction for Mandalay Water
                Boom. It combines the best of waves and the sea to give you
                amazing excitement under controlled conditions.
              </p>
              <!-- <p class="text-info">Read More</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Facilities -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6" style="background-color: rgb(17, 155, 165)">
          <h1 class="text-white text-center p-5">Facilities</h1>
          <div class="row text-center">
            <div
              class="col-md-6 text-white px-5"
              style="border-right: 1px solid white"
            >
              <p>
                <img
                  src="../../public/icons/treatment.png"
                  class="w-25 h-25"
                  alt="Parking Free!"
                />
              </p>
              <h4 class="fw-bold">Parking Free</h4>
              <p>Parking is Free at Mandalay Water Boom!</p>
              <p>( May Need More Info )</p>
            </div>
            <div class="col-md-6 text-white px-5">
              <p>
                <img
                  src="../../public/icons/hotel.png"
                  class="w-25 h-25"
                  alt="Lockers and Toilets!"
                />
              </p>
              <h4 class="fw-bold">Lockers and Toilets</h4>
              <p>
                To make your visit more pleasant, we provide lockers and toilets
                for your convenience.
              </p>
            </div>
          </div>
          <div class="row my-5 text-center">
            <div
              class="col-md-6 text-white px-5"
              style="border-right: 1px solid white"
            >
              <p>
                <img
                  src="../../public/icons/running.png"
                  class="w-25 h-25"
                  alt="First Aid Clinic!"
                />
              </p>
              <h4 class="fw-bold">First Aid clinic</h4>
              <p>
                Mandalay Water Boom is staffed with a fully equipped first aid
                center, run by trained professionals who are on hand to assist
                you as required.m
              </p>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
        <div
          class="col-md-6 d-flex align-items-center mt-3 animate__animated animate__fadeInDown animate__duration-5s"
        >
          <img
            src="../../public/staticImages/home2.jpg"
            class="img-fluid shadow-lg"
            alt="Contact Image"
          />
        </div>
      </div>
    </div>

    <!-- Gallery Done -->
    <div class="container my-5">
      <div class="row text-center my-5">
        <h1 class="text-info fw-bolder">Gallery</h1>
      </div>
      <div class="row">
        <div class="col-md-4 mb-4 hello">
          <img
            src="../../public/staticImages/gallery(home1).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
        <div class="col-md-4 mb-4">
          <img
            src="../../public/staticImages/gallery(home2).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
        <div class="col-md-4 mb-4">
          <img
            src="../../public/staticImages/gallery(home3).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
        <div class="col-md-4 mb-4">
          <img
            src="../../public/staticImages/gallery(home6).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
        <div class="col-md-4 mb-4">
          <img
            src="../../public/staticImages/gallery(home4).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
        <div class="col-md-4 mb-4">
          <img
            src="../../public/staticImages/gallery(home5).jpg"
            class="img-fluid"
            alt="Gallery Image"
          />
        </div>
      </div>
    </div>

    <!-- Rating Done -->
    <div style="background-color: rgb(17, 155, 165)">
      <div class="container py-5 text-white fw-bolder">
        <div class="row">
          <div
            class="col-md-3 col-6 text-center"
            style="border-right: 1px solid white"
          >
            <p>
              <font-awesome-icon icon="users" class="fa-3x" />
            </p>
            <h2 class="my-3 fw-bolder" id="val1"></h2>
            <h4>HAPPY CLIENTS</h4>
          </div>
          <div
            class="col-md-3 col-6 text-center"
            style="border-right: 1px solid white"
          >
            <p>
              <font-awesome-icon :icon="['fas', 'trophy']" class="fa-3x" />
            </p>
            <h2 class="my-3 fw-bolder" id="val2">120</h2>
            <h4>AWARDS WON</h4>
          </div>
          <div
            class="col-md-3 col-6 text-center"
            style="border-right: 1px solid white"
          >
            <p>
              <font-awesome-icon :icon="['fas', 'swimmer']" class="fa-3x" />
            </p>
            <h2 class="my-3 fw-bolder" id="val3"></h2>
            <h4>DAILY SWIMMERS</h4>
          </div>
          <div
            class="col-md-3 col-6 text-center"
            style="border-right: 1px solid white"
          >
            <p>
              <font-awesome-icon :icon="['fas', 'heart']" class="fa-3x" />
            </p>
            <h2 class="my-3 fw-bolder" id="val4"></h2>
            <h4>TOTAL PROJECTS</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- Reviews Done -->
    <div class="image-container position-relative mb-5 text-center reviews">
      <img
        src="../../public/staticImages/review(bg).jpg"
        class="img"
        alt="Review Image"
      />
      <!-- <h1 class="text-center py-5">Testimonials</h1> -->
      <div class="container position-absolute top-50 start-50 translate-middle">
        <swiper
          :spaceBetween="30"
          :centeredSlides="true"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
        >
          <swiper-slide v-for="(image, index) in reviews" :key="index">
            <div>
              <img
                src="../../public/staticImages/logo.png"
                class="pt-3"
                style="height: 50px; width: 50px"
                alt=""
              />
            </div>
            <div>
              <img
                :src="image"
                alt="Image Slide"
                class="py-4"
                style="border-radius: 3px; width: 50%; height: 30%"
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <FooterView></FooterView>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

// Import Anime.js
import anime from "animejs/lib/anime.es.js";

import HeaderView from "./Layouts/HeaderView.vue";
import FooterView from "./Layouts/FooterView.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    HeaderView,
    FooterView,
  },
  data() {
    return {
      images: [
        require("@/assets/banner/banner1.png"),
        require("@/assets/banner/banner2.png"),
        require("@/assets/banner/banner3.jpg"),
      ],
      reviews: [
        require("@/assets/review/review1.jpg"),
        require("@/assets/review/review2.jpg"),
      ],
    };
  },
  mounted() {
    document.title = "Mandalay Water Boom";
    const value1 = this.$el.querySelector("#val1");
    const value2 = this.$el.querySelector("#val2");
    const value3 = this.$el.querySelector("#val3");
    const value4 = this.$el.querySelector("#val4");

    console.log(value1);

    anime({
      targets: value1,
      innerHTML: [0, 1200],
      easing: "linear",
      duration: 10000,
      round: 1, // Will round the animated value to 1 decimal
    });
    anime({
      targets: value2,
      innerHTML: [0, 120],
      easing: "linear",
      duration: 10000,
      round: 1, // Will round the animated value to 1 decimal
    });
    anime({
      targets: value3,
      innerHTML: [0, 95],
      easing: "linear",
      duration: 10000,
      round: 1, // Will round the animated value to 1 decimal
    });
    anime({
      targets: value4,
      innerHTML: [0, 450],
      easing: "linear",
      duration: 10000,
      round: 1, // Will round the animated value to 1 decimal
    });
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
};
</script>

<style>
.swiper-slide img {
  width: 100%;
  height: 480px;
  text-align: center;
}

@media only screen and (min-device-width: 375px) and (max-width: 1024px) {
  .homePage .card {
    width: 300px !important;
  }
  .banner img {
    width: 100% !important;
    height: 150px !important;
  }
  .banner .container h1 {
    font-size: 0.7rem !important;
    letter-spacing: 1.2px !important;
    color: rgb(17, 98, 165);
  }
  .banner .container h2 {
    font-size: 0.5rem !important;
  }

  .banner .container .btn {
    display: none;
  }

  .reviews .container {
    width: 300px !important;
  }
  .reviews .img {
    height: 300px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-width: 1920px) {
  .homePage .card {
    width: 650px;
    border: 0px;
  }
  .reviews .container {
    border: 5px solid white;
    border-radius: 80px;
  }
  .reviews .img {
    width: 100%;
    height: 650px;
  }
}
</style>
