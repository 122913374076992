<template>
  <div class="sticky-top">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand me-5" href="#">
          <img
            src="../../../public/staticImages/logo.png"
            class="img-fluid"
            style="height: 100px"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item me-5">
              <router-link to="/" class="text-decoration-none text-white"
                >Home</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link
                to="/about-us"
                class="text-decoration-none text-white"
                >About Us</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link
                to="/bar-and-restaurant"
                class="text-decoration-none text-white"
                >Bar and Restaurant</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link
                to="/facilities"
                class="text-decoration-none text-white"
                >Facilities</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link
                to="/shop-with-us"
                class="text-decoration-none text-white"
                >Shop with Us</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link to="/gallery" class="text-decoration-none text-white"
                >Gallery</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link to="/vlog" class="text-decoration-none text-white"
                >Vlog</router-link
              >
            </li>
            <li class="nav-item me-5">
              <router-link
                to="/contact-us"
                class="text-decoration-none text-white"
                >Contact Us</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Static navbar -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header bg-info">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body" id="offcanvasExampleLabel">
        <ul class="list-unstyled">
          <li>
            <router-link to="/" class="text-decoration-none text-white"
              >Home</router-link
            >
          </li>
          <li>
            <router-link to="/about-us" class="text-decoration-none text-white"
              >About Us</router-link
            >
          </li>
          <li>
            <router-link
              to="/bar-and-restaurant"
              class="text-decoration-none text-white"
              >Bar and Restaurant</router-link
            >
          </li>
          <li>
            <router-link
              to="/facilities"
              class="text-decoration-none text-white"
              >Facilities</router-link
            >
          </li>
          <li>
            <router-link
              to="/shop-with-us"
              class="text-decoration-none text-white"
              >Shop with Us</router-link
            >
          </li>
          <li>
            <router-link to="/gallery" class="text-decoration-none text-white"
              >Gallery</router-link
            >
          </li>
          <li>
            <router-link to="/vlog" class="text-decoration-none text-white"
              >Vlog</router-link
            >
          </li>
          <li>
            <router-link
              to="/contact-us"
              class="text-decoration-none text-white"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<style scoped>
div.offcanvas {
  width: 65% !important;
  background-color: black;
}

.offcanvas-body ul li {
  padding: 10px !important;
}
</style>
