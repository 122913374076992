<template>
  <div class="footer">
    <footer class="text-white">
      <div class="image-container position-relative">
        <img
          src="../../../public/staticImages/footer.jpg"
          class="myFooter"
          style="height: 400px; width: 100%"
          alt="Footer Image"
        />
        <div
          class="container position-absolute top-50 start-50 translate-middle"
        >
          <div class="row p-5">
            <div class="col-lg-4 col-12">
              <img
                src="../../../public/staticImages/logo.png"
                class="img-fluid mt-3"
                alt=""
              />
              <p class="mt-4">
                Our outdoor pool offers a relaxing and enjoyable escape from the
                bustle of city life. Our lovely “oasis in the heart of city” has
                always been a delight for returning and new pool club members of
                all ages.
              </p>
            </div>
            <div class="col-lg-4 col-12">
              <h2>NEWSLETTER</h2>
              <form action="">
                <input
                  type="text"
                  class="form-control rounded-0 my-4 p-3"
                  placeholder="Enter Your Name"
                />
                <input
                  type="text"
                  class="form-control rounded-0 p-3"
                  placeholder="Enter Your Email"
                />
                <button
                  class="btn btn-warning form-control p-3 text-center rounded-0 text-white my-3 fw-bolder"
                >
                  SUBSCRIBE
                </button>
              </form>
            </div>
            <div class="col-lg-4 col-12">
              <h2>SOCIAL MEDIA CHANNELS</h2>
              <a
                href="https://www.facebook.com/waterboommandalay"
                target="_blank"
              >
                <img
                  src="../../../public/icons/facebook.png"
                  class="m-5"
                  style="width: 40px; height: 40px"
                  alt="Facebook Image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Copyright Section -->
    <div class="text-center bg-info py-3 mt-md-0 fw-bolder">
      <p>
        &copy; Developed by Openmind Copyright © 2023/ All rights reserved by
        Mandalay Waterboom
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@media only screen and (min-device-width: 375px) and (max-width: 1024px) {
  footer .container {
    top: 0;
    left: 0;
  }
  footer .myFooter {
    width: 100%;
    height: 800px !important;
  }
}
</style>
